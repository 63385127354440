<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "แก้ไขสั่งซื้อรถเข้าสต็อก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขสั่งซื้อรถเข้าสต็อก",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "สั่งซื้อรถเข้าสต็อก",
          active: false,
          href: "/vehicle-po",
        },
        {
          text: "แก้ไขสั่งซื้อรถเข้าสต็อก",
          active: true,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      rowsPage: "",
      rowsProduct: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      rowsDetail: [],
      loading: false,
      userBranchAccessId: "",
      submitted: false,
      searchform: "",
      selectedPO: [],
      interiorColor: "",
      exteriorColor: "",
      optioninteriorColor: [],
      optionExteriorColor: [],
      poItem: {
        nameTh: "",
        prodCode: "",
        pricePerOne: "",
        amount: 0,
        poTotalPrice: "",
        poDcPer: 0,
        poDcPrice: "",
        poNetPrice: "",
        exteriorColorNameTh: {},
        interiorColorNameTh: {},
      },
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
      ],
      poDate: "",
      supId: "",
      userBranchId: "",
      status: "",

      poVatPer: "",
      poTotalPrice: "",
      poDcPer: 0,
      poDcPrice: 0,
      poNetPrice: "",
      poVatPrice: "",
      poGrandTotalPrice: "",

      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",

      productAmount: "",
      productPricePerOne: "",
      productTotalPrice: "",
      productDcPer: "",
      productDcPrice: "",
      productNetPrice: "",
      productVatPer: "",
      productVatPrice: "",
      productGrandTotalPrice: "",

      poCode: "",

      nameState: null,

      userBranch: "",
      prodId: "",
      filterOn: [],
      rowsPO: [],
      rowsSuppliers: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
        },
        {
          key: "action",
          label: appConfig.action,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranchAccessId = user.branchId;
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
    if (this.$route.params.poId) {
      this.getDataDetail();
      this.getDetailPo();
      // if(this.userBranchId.branchId != null) {
      //     this.getDataSuppliers();
      // }
    }
    // this.getBranchProduct();
    // this.getDataSuppliers();
    this.getBranchExterior();
    this.getBranchInterior();
  },
  created() {
    // this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    numberFormats(x) {
      const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(x);
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getDataDetail: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-po/show", {
          params: {
            poId: atob(this.$route.params.poId),
          },
        })
        .then((response) => {
          this.userBranchId = {
            branchId: response.data.data.branchId,
            nameTh: response.data.data.branchName,
          };
          this.supId = {
            supId: response.data.data.supId,
            nameTh: response.data.data.supplierNameTh,
          };
          this.poCode = response.data.data.poCode;
          this.status = response.data.data.status;
          this.poDate = response.data.data.poDate;
          this.poVatPer = response.data.data.vatPer;
          this.poDcPer = response.data.data.dcPer;
          this.poDcPrice = response.data.data.dcPrice;
          this.poGrandTotalPrice = response.data.data.grandTotalPrice;
          this.poNetPrice = response.data.data.netPrice;
          this.poTotalPrice = response.data.data.totalPrice;
          this.poVatPrice = response.data.data.vatPrice;
          //   this.rowsPO = response.data.data;
          //   this.rowsPO.to = response.data.to;
          //   this.rowsPO.from = response.data.from;
          //   this.rowsPO.total = response.data.total;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDataSuppliers: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/master/suppliers", {
          params: {
            branchId:
              this.userBranchId != null
                ? this.userBranchId.branchId
                : undefined,
          },
        })
        .then((response) => {
          this.rowsSuppliers = response.data.data;
          this.rowsSuppliers.to = response.data.to;
          this.rowsSuppliers.from = response.data.from;
          this.rowsSuppliers.total = response.data.total;
          this.supId = null;
          this.searchform = null;
          this.rowsProduct = [];
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getDetailPo: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-po/product", {
          params: {
            poId: atob(this.$route.params.poId),
          },
        })
        .then((response) => {
          this.rowsDetail = response.data.data;
          this.rowsDetail.forEach((item, index) => {
            // console.log(item)
            this.rowsDetail[index].exteriorColorNameTh = {
              nameTh: item.exteriorColorNameTh,
              exteriorColorId: item.exteriorColorId,
            };
            this.rowsDetail[index].interiorColorNameTh = {
              nameTh: item.interiorColorNameTh,
              interiorColorId: item.interiorColorId,
            };
          });
          this.calculatePo();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    getBranchProduct: function(name) {
      useNetw
        .get("api/vehicle-po/branch-product", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            nameTh: name,
            image: 0,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.rowsProduct = response.data.data;
          // this.rowsDetail = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    getBranchExterior: function() {
      useNetw
        .get("api/vehicle-po/branch-exterior-color", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.optionExteriorColor = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    getBranchInterior: function() {
      useNetw
        .get("api/vehicle-po/branch-interior-color", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            branchId: this.userBranchAccessId,
            // this.userBranchAccessId != null
            //   ? this.userBranchId.branchId
            //   : undefined,
          },
        })
        .then((response) => {
          this.optioninteriorColor = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.$router.push("/login");
        })
        .then(() => {});
    },
    // submitVehiclePO() {
    //   this.overlayFlag = true;
    //   useNetw
    //     .post("api/vehicle-po/store", {
    //       branchId:
    //         this.userBranchId != null ? this.userBranchId.branchId : undefined,
    //       supId: this.supId != null ? this.supId.supId : undefined,
    //       poDate: this.poDate,
    //       vatPer: this.vatPer,
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.postSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //       this.$router.push({ name: "vehicle-po" });
    //       //   this.getData();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    addDetailVehiclePO(poItem) {
      this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .post("api/vehicle-po/product/store", {
          poId: atob(this.$route.params.poId),
          prodId: this.prodId != null ? this.prodId : undefined,
          amount: this.poItem.amount,
          pricePerOne: this.poItem.pricePerOne,
          totalPrice: this.poItem.poTotalPrice,
          dcPer: this.poItem.poDcPer,
          dcPrice: this.poItem.poDcPrice,
          netPrice: this.poItem.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poItem.vatPrice,
          grandTotalPrice: this.poItem.poGrandTotalPrice,
          poTotalPrice: parseFloat(this.poTotalPrice) + poItem.totalPrice,
          poDcPer: this.poDcPer,
          poDcPrice: parseFloat(this.poDcPrice), // parseFloat(this.poDcPrice) + poItem.dcPrice,
          poNetPrice: parseFloat(this.poNetPrice) + poItem.poNetPrice,
          poVatPer: this.poVatPer,
          poVatPrice: parseFloat(this.poVatPrice) + poItem.vatPrice,
          poGrandTotalPrice:
            parseFloat(this.poGrandTotalPrice) + poItem.poGrandTotalPrice,
          exteriorColorId: this.exteriorColor != null ? this.exteriorColor.exteriorColorId : undefined,
          interiorColorId: this.interiorColor != null ? this.interiorColor.interiorColorId : undefined,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$refs["modalAmout"].hide();
          this.getDataDetail();
          this.getDetailPo();
          this.exteriorColor = null;
          this.interiorColor = null;
          this.searchform = null;
          this.poItem.amount = 0;
          // this.calculateProduct();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    updateVehiclePO() {
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-po/update", {
          poId: atob(this.$route.params.poId),
          supId: this.supId != null ? this.supId.supId : undefined,
          poDate: this.poDate,
          totalPrice: this.poTotalPrice,
          dcPer: this.poDcPer,
          dcPrice: this.poDcPrice,
          netPrice: this.poNetPrice,
          vatPer: this.poVatPer,
          vatPrice: this.poVatPrice,
          grandTotalPrice: this.poGrandTotalPrice,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.getDataDetail();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    approveVehiclePO() {
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-po/submit", {
          poId: atob(this.$route.params.poId),
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    alert(poItem, index) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deletePO(poItem, index);
          }
        });
    },
    updateDetailVehiclePO(poItem) {
      this.calculateProduct(poItem);
      this.overlayFlag = true;
      useNetw
        .put("api/vehicle-po/product/update", {
          poId: atob(this.$route.params.poId),
          podId: poItem.podId,
          prodId: poItem.prodId,
          amount: poItem.amount,
          pricePerOne: poItem.pricePerOne,
          totalPrice: poItem.totalPrice,
          dcPer: poItem.dcPer,
          dcPrice: poItem.dcPrice,
          netPrice: poItem.netPrice,
          vatPer: poItem.vatPer,
          vatPrice: poItem.vatPrice,
          grandTotalPrice:
            parseFloat(poItem.netPrice) + parseFloat(poItem.vatPrice),
          poTotalPrice: parseFloat(this.poTotalPrice),
          poDcPer: this.poDcPer,
          poDcPrice: parseFloat(this.poDcPrice),
          poNetPrice: parseFloat(this.poNetPrice),
          poVatPer: this.poVatPer,
          poVatPrice: parseFloat(this.poVatPrice),
          poGrandTotalPrice: parseFloat(this.poGrandTotalPrice),
          exteriorColorId: poItem.exteriorColorNameTh != null ? poItem.exteriorColorNameTh.exteriorColorId : undefined,
          interiorColorId: poItem.interiorColorNameTh != null ? poItem.interiorColorNameTh.interiorColorId : undefined,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getDataDetail();
          this.getDetailPo();
          // this.$router.push({ name: "vehicle-po" });
          //   this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    deletePO: function(poItem) {
      // this.rowsDetail.splice(index, 1);
      this.calculatePo(this.rowsDetail);

      this.loading = true; //skeleton true
      useNetw
        .delete("api/vehicle-po/product/delete", {
          data: {
            podId: poItem.podId,
            prodId: this.poItem.prodId,
            poTotalPrice: this.poTotalPrice - poItem.totalPrice,
            poDcPer: this.poDcPer,
            poDcPrice: this.poDcPrice,
            poNetPrice: this.poNetPrice - poItem.netPrice,
            poVatPer: this.poVatPer,
            poVatPrice: this.poVatPrice - poItem.vatPrice,
            poGrandTotalPrice: this.poGrandTotalPrice - poItem.grandTotalPrice,
          },
        })
        .then((response) => {
          // alert("ต้องการลบหรือไม่")
          // this.getPoShow(this.poId);
          // this.rowPoParts.push(this.rowPoArr)
          this.getDetailPo();
          Swal.fire(
            appConfig.swal.title.deleterSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.overlayFlag = false;
          this.loading = false; //skeleton false
        });
    },
    alertSubmit() {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการยืนยันการสั่งซื้อหรือไม่ !!",
          icon: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.approveVehiclePO();
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "ยกเลิกเรียบร้อยแล้ว",
              "error"
            );
          }
        });
    },
    customLabel({ prodCode, nameTh }) {
      return `[${prodCode}] - ${nameTh != null ? nameTh : ""}`;
    },
    showModal(option) {
      this.selectedPO = option;
      this.poItem.prodId = option.prodId;
      this.poItem.pricePerOne = parseFloat(option.price);
      // this.poItem.totalPrice = option.price;
      // this.poItem.netPrice = option.price;
      this.poItem.nameTh = option.nameTh;
      // this.poItem.prodCode = option.partId;
      this.$refs.modalAmout.show(option);
    },
    calculatePo() {
      let poTotalPrice, poDcPrice, poNetPrice, poVatPrice, poGrandTotalPrice;

      poTotalPrice = this.rowsDetail.reduce(function(sum, item) {
        //ค่าแรงสุทธิ
        var netPrice = parseFloat(item.netPrice);
        if (!isNaN(netPrice)) {
          return sum + netPrice;
        }
      }, 0);

      this.poTotalPrice = poTotalPrice;
      poDcPrice =
        parseFloat(this.poTotalPrice) * parseFloat(this.poDcPer / 100);
      if (!isNaN(poDcPrice)) {
        this.poDcPrice = poDcPrice;
      }
      poNetPrice = parseFloat(this.poTotalPrice) - parseFloat(this.poDcPrice);
      if (!isNaN(poNetPrice)) {
        this.poNetPrice = poNetPrice;
      }

      poVatPrice = (this.poNetPrice / 100) * parseFloat(this.poVatPer);
      if (!isNaN(poVatPrice)) {
        this.poVatPrice = poVatPrice;
      }

      poGrandTotalPrice =
        parseFloat(this.poNetPrice) + parseFloat(this.poVatPrice);
      if (!isNaN(poGrandTotalPrice)) {
        this.poGrandTotalPrice = poGrandTotalPrice;
      }
    },
    calculateProduct(item) {
      item.totalPrice = parseFloat(item.amount) * parseFloat(item.pricePerOne);
      item.dcPrice =
        (parseFloat(item.totalPrice) / 100) * parseFloat(item.dcPer);
      item.netPrice = parseFloat(item.totalPrice) - parseFloat(item.dcPrice);
      this.prodId = item.prodId;
      this.productPricePerOne = parseFloat(this.poItem.pricePerOne);
      this.poItem.poTotalPrice =
        parseFloat(item.amount) * parseFloat(item.pricePerOne);
      // this.productDcPer = (this.poItem.pricePerOne * 100) / 7
      this.poItem.poDcPrice =
        parseFloat(this.poItem.poTotalPrice / 100) *
        parseFloat(this.poItem.poDcPer);
      this.poItem.poNetPrice =
        parseFloat(this.poItem.poTotalPrice) -
        parseFloat(this.poItem.poDcPrice);
      this.poItem.vatPrice =
        (parseFloat(this.poItem.poNetPrice) / 100) * parseFloat(this.poVatPer);
      this.poItem.poGrandTotalPrice =
        parseFloat(this.poItem.poNetPrice) + parseFloat(this.poItem.vatPrice);
      this.calculatePo();
      // this.updateDetailVehiclePO(item);
    },
    // deleteVehicle(rowsPO) {
    //   this.vehicleId = rowsPO;
    //   this.overlayFlag = true;
    //   useNetw
    //     .delete("api/vehicle/delete", {
    //       data: {
    //         vehicleId: this.vehicleId,
    //       },
    //     })
    //     .then((response) => {
    //       Swal.fire(
    //         appConfig.swal.title.deleterSuccess,
    //         JSON.stringify(response.data.message),
    //         appConfig.swal.type.success
    //       );
    //     //   this.getData();
    //     })
    //     .catch((err) => {
    //       Swal.fire(
    //         appConfig.swal.title.error,
    //         JSON.stringify(err.message),
    //         appConfig.swal.type.error
    //       );
    //     })
    //     .then(() => {
    //       this.overlayFlag = false;
    //     });
    // },
    // alertDelete(vehicleId) {
    //   const swalWithBootstrapButtons = Swal.mixin({
    //     customClass: {
    //       confirmButton: "btn btn-success",
    //       cancelButton: "btn btn-danger ms-2",
    //     },
    //     buttonsStyling: false,
    //   });

    //   swalWithBootstrapButtons
    //     .fire({
    //       title: "ต้องการลบหรือไม่?",
    //       text: "ต้องการ Vehicle นี้หรือไม่ !!",
    //       icon: "warning",
    //       confirmButtonText: "OK",
    //       cancelButtonText: "Cancel!",
    //       showCancelButton: true,
    //     })
    //     .then((result) => {
    //       if (result.value) {
    //         this.deleteVehicle(vehicleId);
    //       } else if (
    //         /* Read more about handling dismissals below */
    //         result.dismiss === Swal.DismissReason.cancel
    //       ) {
    //         swalWithBootstrapButtons.fire(
    //           "Cancelled",
    //           "ยกเลิกเรียบร้อยแล้ว",
    //           "error"
    //         );
    //       }
    //     });
    // },
  },
  middleware: "authentication",
};
</script>
<style>
.alert-fixed {
  position: fixed;
  top: 50px;
  left: 65%;
  width: 30%;
  z-index: 9999;
  border-radius: 0px;
}

.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-alert
      class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
      :show="dismissCountDown"
      dismissible
      fade
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
    >
      <p class="float-end">{{ dismissCountDown }} วินาที...</p>
      <p>แจ้งเตือน</p>
      <hr />
      <p>{{ resalert }}</p>
      <!-- <b-progress
        variant="warning"
        :max="dismissSecs"
        :value="dismissCountDown"
        height="4px"
      ></b-progress> -->
    </b-alert>
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div
              class="col-12 badge"
              style="background-color: #26B99A"
              v-if="this.status === 'APV'"
            >
              <h4 class="mt-2 text-white">
                สั่งซื้อรถแล้ว
              </h4>
            </div>
            <div
              class="col-12 badge"
              style="background-color: #e74646"
              v-if="this.status === 'CAN'"
            >
              <h4 class="mt-2 text-white">
                ยกเลิกแล้ว
              </h4>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>รหัสสั่งซื้อ:
                            <b-form-input
                              v-model="poCode"
                              type="text"
                              disabled
                              class="form-control"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>สาขา:
                            <multiselect
                              v-model="userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                              @input="getDataSuppliers"
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            <code> * </code>ผู้ผลิต:
                            <multiselect
                              v-model="supId"
                              label="nameTh"
                              type="search"
                              :options="rowsSuppliers"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-8 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>วันที่สั่งซื้อ:
                            <date-picker
                              v-model="poDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-4 col-sm-6 col-md-2">
                          <div class="mb-3 position-relative">
                            <code> * </code>ภาษี:
                            <b-form-input
                              v-model="poVatPer"
                              type="number"
                              class="form-control text-end"
                            ></b-form-input>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <p class="card-title-desc">รายการสินค้า</p>
                </div>
                <div class="col-6">
                  <!-- <p
                    class="badge bg-success font-size-14 float-end"
                    v-if="this.status === 'APV'"
                  >
                    สั่งซื้อรถแล้ว
                  </p>
                  <p
                    class="badge bg-warning font-size-14 float-end"
                    v-if="this.status === 'DFT'"
                  >
                    ร่าง
                  </p>
                  <p
                    class="badge bg-danger font-size-14 float-end"
                    v-if="this.status === 'CAN'"
                  >
                    ยกเลิกแล้ว
                  </p> -->
                  <!-- <b-button class="float-end btn btn-success">
                    สั่งซื้อรถเพิ่ม
                  </b-button> -->
                </div>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">รหัสสินค้า</th>
                      <th scope="col">ชื่อสินค้า</th>
                      <th scope="col">ชื่อรุ่นรถ</th>
                      <th scope="col">สีภายนอก</th>
                      <th scope="col">สีภายใน</th>
                      <th scope="col">ราคา/หน่วย</th>
                      <th scope="col">จำนวน</th>
                      <th scope="col">รวม</th>
                      <th scope="col">ส่วนลด</th>
                      <th scope="col">ส่วนลดยอด</th>
                      <th scope="col">หลังลด</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="this.rowsDetail.length > 0">
                    <tr v-for="(poItem, index) in rowsDetail" :key="index">
                      <th scope="row">{{ index + 1 }}</th>
                      <!-- <td>{{ poItem }}</td> -->
                      <td>{{ poItem.prodCode }}</td>
                      <td style="min-width: 220px">{{ poItem.productNameTh }}</td>
                      <td style="min-width: 100px">{{ poItem.modelNameTh }}</td>
                      <td>
                        <multiselect
                          v-model="poItem.exteriorColorNameTh"
                          label="nameTh"
                          style="width: 150px"
                          type="search"
                          :options="optionExteriorColor"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="updateDetailVehiclePO(poItem)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </td>
                      <td>
                        <multiselect
                          v-model="poItem.interiorColorNameTh"
                          label="nameTh"
                          style="width: 130px"
                          type="search"
                          :options="optioninteriorColor"
                          :show-labels="false"
                          open-direction="bottom"
                          placeholder=""
                          @input="updateDetailVehiclePO(poItem)"
                        >
                          <span slot="noResult">ไม่พบข้อมูล</span>
                        </multiselect>
                      </td>
                      <!-- <td>{{ poItem.exteriorColorNameTh != null ? poItem.exteriorColorNameTh : '-' }}</td>
                      <td>{{ poItem.interiorColorNameTh != null ? poItem.interiorColorNameTh : '-' }}</td> -->
                      <td class="">
                        <input
                          v-model="poItem.pricePerOne"
                          style="width: 130px"
                          class="form-control text-end number"
                          :disabled="status == 'APV'"
                          type="number"
                          @keyup.enter="updateDetailVehiclePO(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="updateDetailVehiclePO(poItem)"
                        />
                      </td>

                      <td class="">
                        <input
                          :disabled="status == 'APV'"
                          style="width: 60px"
                          v-model="poItem.amount"
                          type="number"
                          class="form-control text-end Dc"
                          @keyup.enter="updateDetailVehiclePO(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="updateDetailVehiclePO(poItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          style="width: 130px"
                          v-model="poItem.totalPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="">
                        <input
                          :disabled="status == 'APV'"
                          style="width: 90px"
                          v-model="poItem.dcPer"
                          class="form-control text-end Dc"
                          type="number"
                          @keyup.enter="updateDetailVehiclePO(poItem)"
                          @input="calculateProduct(poItem)"
                          @change="updateDetailVehiclePO(poItem)"
                        />
                      </td>
                      <td class="text-end" @change="calculateProduct(poItem)">
                        <input
                          disabled
                          style="width: 130px"
                          v-model="poItem.dcPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td class="text-end">
                        <input
                          disabled
                          style="width: 130px"
                          v-model="poItem.netPrice"
                          class="form-control text-end number"
                          @input="calculateProduct(poItem)"
                          @change="calculateProduct(poItem)"
                        />
                      </td>
                      <td v-if="status == 'DFT'">
                        <ul class="list-inline mb-0 action">
                          <li class="list-inline-item">
                            <a
                              class="px-2 text-danger"
                              v-b-tooltip.hover
                              title="Delete"
                              @click="alert(poItem, index)"
                            >
                              <i class="uil uil-trash-alt font-size-18"></i>
                            </a>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body">
              <p class="card-title-desc">ค้นหารายการสินค้า</p>

              <div class="row">
                <div class="col-sm-12 col-md-12 align-items-center">
                  <div class="row align-items-center">
                    <!-- <div class="row">
                      <span class="text-center font-size-16">ไม่พบรายการ</span>
                    </div>
                    <hr /> -->
                    <div class="row">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="mb-3 position-relative">
                            <label for="partsCode"
                              ><code>*</code> ค้นหารหัสรถ,ชื่อรถ</label
                            >
                            <multiselect
                              id="partsCode"
                              v-model="searchform"
                              :options="rowsProduct"
                              label="prodCode"
                              :custom-label="customLabel"
                              :show-labels="false"
                              track-by="prodCode"
                              @input="showModal(searchform)"
                              :loading="loading"
                              :close-on-select="true"
                              :internal-search="true"
                              open-direction="bottom"
                              @search-change="getBranchProduct($event)"
                              :disabled="status == 'APV'"
                            >
                            </multiselect>
                          </div>
                        </div>

                        <div></div>
                      </div>

                      <b-modal
                        ref="modalAmout"
                        title="ระบุจำนวน"
                        hide-footer
                        size="sm"
                        centered
                        :state="nameState"
                      >
                        <div class="row">
                          <!-- <label for="amount"><code>*</code> จำนวน</label> -->
                          <span class="badge bg-success mb-3">
                            <h5 class="mt-2 text-white">
                              ({{ this.poItem.nameTh }})
                            </h5></span
                          >
                          <div class="col-6">
                            <label> สีภายนอก </label>
                            <multiselect
                              v-model="exteriorColor"
                              label="nameTh"
                              type="search"
                              :options="optionExteriorColor"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                          <div class="col-6">
                            <label> สีภายใน </label>
                            <multiselect
                              v-model="interiorColor"
                              label="nameTh"
                              type="search"
                              :options="optioninteriorColor"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                          <div class="col-12 mt-2 mb-2">
                            <label> <code> * </code> ส่วนลด(%)</label>
                            <input
                              :state="nameState"
                              style="text-align: center"
                              id="poDcPer"
                              v-model="poItem.poDcPer"
                              type="number"
                              class="form-control"
                              value="Otto"
                              @input="calculateProduct(poItem)"
                              @change="calculateProduct(poItem)"
                              :class="{
                                'is-invalid':
                                  submitted && $v.poItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.poItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.poItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                          <div class="col-12 mt-2 mb-2">
                            <label> <code> * </code> จำนวน</label>
                            <input
                              :state="nameState"
                              style="text-align: center"
                              id="amount"
                              v-model="poItem.amount"
                              type="number"
                              class="form-control"
                              value="Otto"
                              @input="calculateProduct(poItem)"
                              @change="calculateProduct(poItem)"
                              :class="{
                                'is-invalid':
                                  submitted && $v.poItem.amount.$error,
                              }"
                            />
                            <div
                              v-if="submitted && $v.poItem.amount.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.poItem.amount.required">{{
                                message
                              }}</span>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-12">
                            <div class="text-end">
                              <button
                                @click="addDetailVehiclePO(poItem)"
                                class="btn btn-primary"
                              >
                                บันทึก
                              </button>
                            </div>
                          </div>
                        </div>
                      </b-modal>
                      <!-- end model -->
                    </div>
                    <hr />
                    <div class="card-body">
                      <h4 class="card-title">
                        <h4>
                          <strong>สรุปยอดการสั่งซื้อรถ</strong>
                        </h4>
                      </h4>
                      <hr />
                      <div class="row">
                        <div class="col-md-2 col-sm-3">
                          รายละเอียดการสั่งซื้อรถ
                        </div>
                        <div class="col-md-2 col-sm-3 col-6">
                          <div style="display: inline-block">
                            <div class="text-end">
                              ยอดรวมค่ารถ : <br />
                              ส่วนลดรวม(%) : <br />
                              ส่วนลดรวม(บาท) : <br />
                              จำนวนเงิน : <br />
                              ภาษีรวม(%) : <br />
                              ภาษีรวม(บาท) : <br />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-2 col-sm-5 col-4 text-end">
                          <strong>
                            {{ this.numberFormats(this.poTotalPrice) }} <br />
                            {{ this.poDcPer }}<br />
                            {{ this.numberFormats(this.poDcPrice) }} <br />
                            {{ this.numberFormats(this.poNetPrice) }} <br />
                            {{ this.numberFormats(this.poVatPer) }} <br />
                            {{ this.numberFormats(this.poVatPrice) }}</strong
                          >
                        </div>
                        <div class="col-md-1 col-sm-2 col-2 text-end">
                          บาท<br />
                          %<br />
                          บาท <br />
                          บาท <br />
                          % <br />
                          บาท
                        </div>
                        <!-- มูลค่ารวม -->
                        <div
                          class="col-md-5 col-sm-5 bg-dark text-white m-0 row align-items-center"
                          style="border-radius:10px;margin:auto;justify "
                        >
                          <div class="row align-items-center m-0">
                            <div class="col-12">
                              <div class="row">
                                <div class="col-md-3">มูลค่ารวม :</div>
                                <div class="col-6 font-size-24 text-center">
                                  <strong>{{
                                    Number(
                                      this.poGrandTotalPrice
                                    ).toLocaleString()
                                  }}</strong>
                                </div>
                                <div class="col-3">บาท</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br /><br />
                      <div class="row">
                        <div class="col-12 col-sm-12 col-md-12 text-end">
                          <b-button
                            v-if="this.$route.params.poId || status == 'APV'"
                            class="btn m-2"
                            variant="primary"
                            type="submit"
                            @click="updateVehiclePO"
                          >
                            บันทึกการแก้ไขข้อมูล
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div class="row mb-0 mt-2">
                      <div class="col text-end ms-1">
                        <button
                          :disabled="status == 'APV'"
                          class="btn btn-danger ms-1"
                          @click="alertSubmit()"
                        >
                          ยืนยันการสั่งซื้อ
                        </button>
                        <p>
                          <!-- <code v-if="this.part.status == 'APV'"
                              >***
                              ไม่สามารถบันทึกได้เนื่องจากอยู่ในสถานะยืนยันแล้ว</code
                            > -->
                          <code
                            >*** ถ้ายืนยันการสั่งซื้อแล้ว
                            จะไม่สามารถแก้ไขข้อมูลได้</code
                          >
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
